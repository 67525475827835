.recommended {
    background: var(--bg-black);
    padding: 0 20px;
    box-sizing: border-box;
}

.isSpacing {
    padding-bottom: 80px;
}

.title {
    font-family: PingFangSC-Semibold;
    font-size: 44px;
    color: #191919;
    letter-spacing: 0;
    font-weight: 600;
    padding-top: 40px;
    margin-bottom: 32px;
    padding-left: 20px;
}

.list {
    width: 100%;
    display: flex;
    flex-direction: row;
}


.colmun {
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-right: 2%;
    flex: 1;
}


.colmun:nth-child(2n) {
    margin-right: 0;
}

.item {
    width: 100%;
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
}

.img {
    width: 100%;
    height: 220px;
    margin-bottom: 22px;
}

.more {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 24px;
    color: #93A3B8;
    text-align: center;
    font-weight: 400;
}

.icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(./images/more.png);
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 0px;
}