.PinPaiDom {
	margin-top: 20px;
	height: 572px;
	background: #fff;
	padding: 48px 34px 40px;
	box-sizing: border-box;
}

.sugarclass {
	display: flex;
	justify-content: space-between;
}
.sugarclassdiv {
  width: 116px;
  height: 150px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.classIcon {
	width: 104px;
	height: 104px;
	margin: 0 auto;
}
.mt12{
  margin-top:12px;
}
.mt17{
  margin-top:17px;
}
.text01 {
	font-family: var(--font-family);
	font-size: 24px;
	color: var(--black);
	text-align: center;
	font-weight: 400;
  
  line-height: 34px;

}


.filterclass{
  display: flex;
	justify-content: space-between;
  flex-wrap: wrap;
  margin-top:28px;
}

.filterclassdiv{
  width:154px;
  height: 68px;
  background: var(--bg-black);
  margin-top:12px;
  cursor: pointer;
  user-select: none;
}

.brandclass {
	display: flex;
	justify-content: space-between;
  margin-top:40px;
}

.brandclassdiv {
  width: 110px;
  height: 110px;
  cursor: pointer;
  user-select: none;
  text-align: center;
}
.classIcon2 {
	max-width: 64px;
	max-height: 64px;
	margin: 0 auto;
}