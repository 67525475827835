

.top2{
  position: relative;
  overflow: hidden;
}

.fuceng{
  box-sizing: border-box;
	position: relative;
	margin-top: 444px;
	z-index: 0;
}
.fuceng2{
	padding: 0 20px;
}

.height32{
  height:70px;
}