.code {
  display: flex;
  align-items: center;
  height: 100px;
  padding-bottom: 6px;
  border-bottom: 2px solid rgba(242, 245, 250, 1);
  .left {
    display: flex;
    align-items: center;
    margin-right: 16px;
    flex-grow: 1;
  }
  .codeBtn {
    font-family: PingFangSC-Medium;
    font-size: 28px;
    color: #002fa7;
    text-align: right;
    font-weight: 500;
    white-space:nowrap;
    .timer {
      color: #1e293b;
    }
  }
  input {
    font-family: PingFangSC-Medium;
    font-size: 32px;
    color: #1e293b;
    font-weight: 500;
    height: 100px;
    border: 0;
    flex-grow: 1;
    padding:0;
    &:focus-visible {
      outline: 0;
    }
  }
  input:focus {
    outline: none;
  }
  .close {
    background-image: url(../../images/close_36x36_3x.png);
    width: 32px;
    height: 32px;
    background-size: 100%;
  }
}

.line {
  width: 100%;
  height: 4px;
  background-color: rgba(242, 245, 250, 1);
}
