/* DownloadBar 下载条css  */
.wrapper {
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  background: var(--primary);
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 9;
  a {
    text-decoration: none;
  }
}
.leftBox {
  display: flex;
  align-items: center;
}

.closeBtn {
  width: 40px;
  height: 40px;
  background: url(./images/close_icon.png) no-repeat;
  background-size: cover;
  margin: 40px 0;
}
.middleBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tcc_logo {
  width: 120px;
  height: 120px;
  margin-right: 8px;
  background: url("./images/taoche_icon.png") no-repeat;
  background-size: cover;
}
.w1 {
  font-family: PingFangSC-Semibold;
  color: #fff;
  line-height: 44px;
  font-size: 32px;
  font-weight: 600;
}

.w2 {
  font-family: PingFangSC-Regular;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
.downloadBtn {
  width: 144px;
  height: 60px;
  border-radius: 0;
  background: #ffe600;
  font-size: 24px;
  color: #1e293b;
  letter-spacing: 0;
  text-align: center;
  line-height: 60px;
  position: relative;
}
