.xieyi {
  display: flex;
  align-items: center;
  .checkbox {
    flex-shrink: 0;
    border: 2px solid rgba(203, 213, 225, 1);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 12px;
    &.checked {
      background-image: url(../../images/right_36x36.png);
      background-size: 100%;
      border: 0;
    }
  }
  .text {
    font-family: PingFangSC-Regular;
    font-size: 24px;
    color: #93a3b8;
    line-height: 34px;
    font-weight: 400;
  }
}
