.content{
  font-size:24px;
  line-height: 60px;
  text-align: center;
  width:630px !important;
}



.btncontent{
  display: flex;
  margin-top:20px;

  div+div{
    margin-left:22px;
  }
}


.btn{
  text-align: center;
  font-weight: 500;
  line-height: 76px;
  height: 80px;
  width:100%;
  font-size: 28px;
  font-family: var(--font-family-medium);
  letter-spacing: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.btn1{
  border: 2px solid var(--primary);
  color:var(--primary);
  
}

.btn2{
  border: 2px solid var(--primary);
  background:var(--primary);
  color:white;
}