.go_top {
  width: 72px;
  height: 72px;
  background-size: 100%;
  background-image: url("https://static.taocheche.com/MWeb/components/product/top.png");
  background-repeat: no-repeat;
  position: fixed;
  right: 40px;
  bottom: 154px;
  z-index: 2;
}
