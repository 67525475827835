.login {
  padding: 40px;
  .title {
    font-family: PingFangSC-Medium;
    font-size: 40px;
    color: #1e293b;
    text-align: center;
    font-weight: 500;
    margin-bottom: 32px;
  }

  .btn {
    height: 92px;
    width: 100%;
    background-color: #002fa7;
    line-height: 92px;
    font-family: PingFangSC-Medium;
    font-size: 28px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 500;
    margin-bottom: 24px;
    margin-top: 62px;
  }
}
