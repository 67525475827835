.storeDom {
	margin-top: 20px;
	height: 148px;
	background: #fff;
	display: flex;
	user-select: none;
  position: relative;

}
.storeLeft {
	width: 176px;
	height: 148px;
	flex-shrink: 0;
	background: url('../images/dianpu.png') no-repeat center/100%;
}
.text01 {
	font-family: var(--font-family-bold);
	font-size: 32px;
	color: #ffffff;
	text-align: center;
	font-weight: 600;
	line-height: 44px;
	margin-top: 32px;
}
.text02 {
	font-family: var(--font-family);
	font-size: 24px;
	color: #ffffff;
	text-align: center;
	font-weight: 400;
	line-height: 34px;
	margin-top: 6px;
}

.storeRight {
	width: 534px;
	padding: 32px 40px 32px 24px;
}
.text03 {
	font-family: var(--font-family-bold);
	font-size: 32px;
	color: var(--black);
	font-weight: 600;
	line-height: 44px;
}
.text04 {
	font-family: var(--font-family);
	font-size: 24px;
	color: var(--black);
	font-weight: 400;
	line-height: 34px;
	width: 460px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.text05 {
	font-family: var(--font-family);
	font-size: 22px;
	color: var(--black02);
	font-weight: 400;
	line-height: 44px;
  transform: scale(0.9);
}
.text06{
  font-family: var(--font-family);
	font-size: 22px;
	color: rgb(16,17,41);
	font-weight: 400;
	line-height: 24px;
  transform: scale(0.9);
}
.fujin{
  padding:8px 8px;
  background:rgb(231,233,237);
  position: absolute;
  top:0;
  right:0;
}
.right01 {
	display: flex;
	justify-content: space-between;
}
.right02 {
	display: flex;
	justify-content: flex-start;
}

.right05 {
	width: 24px;
	height: 24px;
	padding: 10px 0 0 4px;
}
.right06 {
	margin-top: 6px;
	display: flex;
}
.cityNameIcon {
	width: 24px;
	height: 24px;
	user-select: none;
}
.cityNameIcon2 {
	flex-shrink: 0;
	width: 24px;
	height: 24px;
	user-select: none;
	padding: 4px 4px 0 0;
}
