.wai {
  position: relative;
}


.carousel__item__pic {
  position: relative;
  height: 100%;
  padding:  0;
}

.navigation{
  position: absolute;
    transform: translateX(-50%);
    left:50%;
    top: 420px;

    height: 4px;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    z-index: 1;
}

.swiperPaginationBullet+.swiperPaginationBullet{
  margin-left: 20px;
  
}
.swiperPaginationBullet {
  display: inline-block;
  width: 40px;
  height: 4px;
  background-color: var(--black);
  opacity:1;
  border-radius: 0;
}
.swiperactive {
  background-color: var(--highlight);
}
