.phone {
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  input {
    font-family: PingFangSC-Medium;
    font-size: 32px;
    color: #1e293b;
    font-weight: 500;
    height: 100px;
    border: 0;
    flex-grow: 1;
    padding: 0;
    border-bottom: 2px solid rgba(242, 245, 250, 1);
    &:focus-visible {
      outline: 0;
      border:0;
    }
  }
  input:focus {
    outline: none;
  }
  .close {
    background-image: url(../../images/close_36x36_3x.png);
    width: 32px;
    height: 32px;
    background-size: 100%;
  }
}
.line {
  width: 100%;
  height: 4px;
  background-color: rgba(242, 245, 250, 1);
}
