.search02 {
}

.search03 {
  width: 710px;
  height: 100px;

  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  display: flex;
}

.fangdajing {
  height: 48px;
  width: 48px;
  margin: 26px 32px;
  padding: 0;
  .fangdajingImage {
    height: 48px;
    width: 48px;
    margin: 0;
  }
}

.search03text {
  font-family: var(--font-family);
  font-size: 28px;
  color: var(--black);
  font-weight: 400;
  line-height: 100px;
  cursor: pointer;
}
.zuji {
  background: rgba(30, 41, 59, 0.3);
  height: 66px;
  overflow: hidden;

  font-family: var(--font-family);
  font-size: 24px;
  color: #ffffff;
  font-weight: 400;
  display: flex;
  line-height: 66px;
  .zujititle {
    padding-left: 32px;
    flex-shrink: 0;
  }
  .zujitext {
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    margin-bottom: -20px;
  }
  .zujitext2 {
    margin-right: 30px;
    flex-shrink: 0;
    color: #ffffff;
  }
}

.search04 {
  max-width: 100%;
  flex: 1;
  height: 72px;

  background: rgba(255, 255, 255, 0.9);
  overflow: hidden;
  display: flex;
  border: 2px solid var(--black);
  margin-left: 10px;

  .fangdajing {
    height: 32px;
    width: 32px;
    margin: 20px;
    padding: 0;
    .fangdajingImage {
      height: 32px;
      width: 32px;
      margin: 0;
    }
  }

  .search03text {
    font-family: var(--font-family);
    font-size: 24px;
    color: var(--black02);
    font-weight: 400;
    line-height: 70px;
    cursor: pointer;
  }
}
