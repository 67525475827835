.top_skeleton {
	width: 100%;
	height: 120px;
	margin-top: 0 !important;
}

.header_skeleton {
	width: 100%;
	height: 380px;
}

.banner_skeleton {
	width: 100%;
	height: 166px;
}

.info_skeleton {
	width: 100%;
	height: 144px;
	margin-top: 20px;
}

.title2_skeleton {
	width: 100%;
	height: 102px;
}

.contentdiv1{
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.contentdiv {
	
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.con1_skeleton {
	width: 344px;
	height: 220px;
	margin-bottom: 22px;
	flex-shrink: 0;
  flex-grow: 0;
}

.con2_skeleton {
	width: 344px;
	height: 373px;
	margin-bottom: 22px;
	flex-shrink: 0;
  flex-grow: 0;

}
