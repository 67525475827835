.more_car_series {
    padding: 32px;
    background-color: #fff;
    box-sizing: border-box;
}

.title {
    font-family: PingFangSC-Semibold;
    font-size: 28px;
    color: #1E293B;
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 20px;
}

.car {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.car:last-child {
    margin-bottom: 0;

}

.car_left {
    margin-right: 20px;
}

.img {
    width: 110px;
    height: 78px;
}

.car_top {
    font-family: PingFangSC-Medium;
    font-size: 28px;
    color: #1E293B;
    letter-spacing: 0;
    font-weight: 500;
    margin-bottom: 4px;
    width: 160px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.car_bottom {
    font-family: PingFangSC-Regular;
    font-size: 24px;
    color: #93A3B8;
    letter-spacing: 0;
    font-weight: 400;
}