
.position02{
  position: absolute;
  z-index: 1;
  
  left:20px;
  font-size:24px;
 
}
.cityNameParent{
  position: relative;
  display: flex;
  height: 80px;
  align-items: center;
  margin-left:20px;
  flex-shrink: 0;
}
.cityName{
  font-family: var(--font-family-medium);
  color:var(--black);
  margin-right:6px;
  user-select: none;
}
.cityNameIcon{
  width:28px; 
  height: 28px;
  user-select: none;
}
 


.fixclass{
  display: flex;
  position: fixed;
  flex-grow: 1;
  top:0px;
  z-index: 20;
  width:750px;
  background: #fff;
   margin-left:-20px;
  padding:0 20px;
  align-items: center;
  box-sizing: border-box;
  opacity: 0;
}