.content {
  position: fixed;
  bottom: 156px;
  left: 50%;
  margin-left: -120px;
  z-index: 2;
  box-sizing: border-box;

  a {
    display: block;
  }

  .openApp {
    /* width: 245px; */
    height: 80px;
    background-color: var(--primary);
    font-family: var(--font-family);
    font-size: 28px;
    color: #fff;
    padding: 20px 52px;
  }
}