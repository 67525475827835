.img {
  display: block;
  width: 100%;
  height: 230px;
}

.content {
  padding: 0 20px 24px 20px;
  background-color: #fff;
  box-sizing: border-box;
}

.title {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  padding-bottom: 4px;
}

.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: PingFangSC-Medium;
  font-size: 32px;
  color: #1e293b;
  font-weight: 500;
}

.star {
  width: 32px;
  height: 32px;
  /* background-image: url(../images/shoucang-16@3x.png); */
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: 0px;
  flex-shrink: 0;
}

.txt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #93a3b8;
  font-weight: 400;
  margin-bottom: 4px;
  margin-top: 4px;
}

.price {
  display: flex;
  align-items: center;
}

.price_big {
  font-family: PingFangSC-Medium;
  font-size: 28px;
  color: #de2222;
  font-weight: 500;
  margin-right: 12px;
}

.price_small {
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #1e293b;
  font-weight: 400;
}

.tag_item {
  margin-top: 10px;
}

.tag {
  width: auto;
  font-family: PingFangSC-Regular;
  font-size: 22px;
  color: #de2222;
  letter-spacing: 0;
  height: 40px;
  line-height: 41px;
  font-weight: 400;
  padding: 0 12px;
  box-sizing: border-box;
  background: #fdeeee;
}

.image_box {
  display: flex;
  flex-direction: column;
  position: relative;
}

.play {
  width: 48px;
  height: 48px;
  background-image: url(../images/play.png);
  background-size: 48px;
  background-repeat: no-repeat;
  background-position: 0px;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.pengTag {
  display: flex;
  background-color: #ff1e1e;
  position: absolute;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 20px;
  padding: 6px 8px;
  border-radius: 8px 0 8px 0;
  align-items: center;
  &::before {
    background-image: url(../images/icon_up.png);
    background-size: 100%;
    width: 16px;
    height: 24px;
    display: block;
    content: " ";
    margin-right: 2px;
  }
}
